// Based off of https://github.shuttercorp.net/web/next-web/blob/130d982bf389a2c65b71f76fea56868f4adea653/packages/shared/constants/seo.ts

export const SCHEMA_URL = 'https://schema.org';

export const SCHEMA_TYPES = {
  ORGANIZATION: 'Organization',
  WEB_SITE: 'WebSite',
} as const;

export const SCRIPT_TYPES = {
  JSON_LD: 'application/ld+json',
} as const;
