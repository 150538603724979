import { useTranslation } from 'next-i18next';

import { CONTRIBUTOR_LOGO, SHUTTERSTOCK_LOGO_WHITE } from '@/constants/global';
import { NAMESPACE_COMMON } from '@/translations/namespaces';

import styles from './siteHeader.module.css';

type siteHeaderLogoProps = {
  light: boolean;

  size: 'small' | 'regular';
};

export const SiteHeaderLogo = ({ light = false, size = 'regular' }: siteHeaderLogoProps) => {
  const { t } = useTranslation(NAMESPACE_COMMON);

  const LOGO_ALT_TEXT = t('shutterstock_contributor');

  return (
    <img
      src={light ? SHUTTERSTOCK_LOGO_WHITE : CONTRIBUTOR_LOGO}
      alt={LOGO_ALT_TEXT}
      className={size === 'small' ? styles.siteHeaderLogoSmall : styles.siteHeaderLogoRegular}
    />
  );
};
