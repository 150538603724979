import { SxProps } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

export const UserIcon = ({ sx }: { sx: SxProps }) => (
  <SvgIcon sx={sx}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 20H4V19C4 17.29 5.29 16 7 16H17C18.71 16 20 17.29 20 19V20ZM17 14H7C4.196 14 2 16.196 2 19V22H22V19C22 16.196 19.804 14 17 14V14ZM12 4C13.654 4 15 5.346 15 7C15 8.654 13.654 10 12 10C10.346 10 9 8.654 9 7C9 5.346 10.346 4 12 4V4ZM12 12C14.757 12 17 9.757 17 7C17 4.243 14.757 2 12 2C9.243 2 7 4.243 7 7C7 9.757 9.243 12 12 12V12Z"
    />
  </SvgIcon>
);
