import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { Button } from '@/components/WithTracking/Button';
import { Link } from '@/components/WithTracking/Link';
import { CommonPageSections, LOHPPageSections, NavTrackActions } from '@/constants/analytics';
import { NON_C2N_ROUTES } from '@/constants/global';
import { PageSection } from '@/lib/analytics/react-data-tracking';
import { NAMESPACE_LOHP } from '@/translations/namespaces';

const mp4 =
  '//videos.ctfassets.net/hrltx12pl8hq/6NBohSUXpumydPAjhlORno/71527fddd32dc4f95926637aaa8ce0f4/LOHP_HeroReel_updated_5.18.18.mp4';
const webm =
  '//videos.ctfassets.net/hrltx12pl8hq/3vJpzLb3wg5bZHKG45V7m6/dec990ea9a379d809fdf69219c6409ef/LOHP_HeroReel_updated_5.18.18.webm';

const styles = {
  heroHeaderVideo: {
    objectFit: 'cover',
    objectPosition: 'center',
    height: ({ spacing }: Theme) => spacing(50),
    width: '100%',
    filter: 'brightness(80%)',
    position: 'absolute',
    top: 0,
    left: 0,
    overflow: 'hidden',
    zIndex: -1,
    borderRadius: 0,
    boxShadow: 'none',
  },
};

const HeroHeader = () => {
  const { t } = useTranslation([NAMESPACE_LOHP]);

  const {
    palette,
    spacing,
    tokens: { font },
    breakpoints,
  } = useTheme();

  const boxHeight = spacing(50);

  return (
    <PageSection value={CommonPageSections.header}>
      <Box data-testid="hero-header-container" sx={{ minHeight: boxHeight, display: 'flex', alignItems: 'center' }}>
        <Box
          data-testid="hero-header-content"
          sx={{
            zIndex: 2,
            minHeight: boxHeight,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            textAlign: 'left',
            marginRight: spacing(0), // Margin to copy the wrap seen on CC-WEB Laptop view (1024px to 1059px)
            [breakpoints.between('md', 'lg')]: {
              marginRight: spacing(9),
            },
          }}
        >
          <Typography
            data-testid="hero-header-title"
            variant="h2"
            component="h1"
            sx={{
              color: palette.text.inverse,
              fontSize: font.size.h2,
              lineHeight: font.lineHeight.small,
              [breakpoints.down('md')]: {
                fontSize: font.size.h3,
              },
            }}
          >
            {t('contributor_lohp_hero_title')}
          </Typography>
          <Typography
            data-testid="hero-header-subtitle"
            component="h2"
            sx={{
              color: palette.text.inverse,
              fontSize: font.size.large,
              lineHeight: font.lineHeight.h4,
              fontWeight: font.weight.normal,
              marginTop: spacing(2),
              marginBottom: spacing(3),
              [breakpoints.down('md')]: {
                fontSize: font.size.regular,
              },
            }}
          >
            {t('contributor_lohp_hero_subtitle')}
          </Typography>
          <Button
            data-testid="hero-header-cta"
            sx={{
              marginRight: 'auto',
            }}
            sxTypography={{
              fontSize: font.size.large,
            }}
            href={NON_C2N_ROUTES.SIGN_UP}
            trackAction={NavTrackActions.signUpLink}
            size="large"
          >
            {t('contributor_lohp_hero_cta')}
          </Button>
        </Box>
        <Box component="video" autoPlay loop muted data-testid="hero-header-video" sx={styles.heroHeaderVideo}>
          <source src={mp4} type="video/mp4" />
          <source src={webm} type="video/webm" />
        </Box>
      </Box>
    </PageSection>
  );
};

const footerStyles = {
  heroFooter: ({ spacing }: Theme) => ({
    minHeight: spacing(68),
    padding: spacing(2),
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundSize: 'cover',
    flexDirection: 'column',
    backgroundImage:
      'linear-gradient(to bottom, rgba(0, 0, 0, 0.5) 0, rgba(0, 0, 0, 0.5) 100%), url(https://submit2.shutterstock.com/public/static/signup-0e89c6488165ab2a9365e36f38a46839.jpg)',
    backgroundPositionY: '30%',
  }),
};

const HeroFooter = (): JSX.Element => {
  const { t } = useTranslation([NAMESPACE_LOHP]);

  const { palette, spacing } = useTheme();

  return (
    <PageSection value={LOHPPageSections.footer}>
      <Box data-testid="hero-footer-container" sx={footerStyles.heroFooter}>
        <Box data-testid="hero-footer-content" sx={{ maxWidth: spacing(42) }}>
          <Typography variant="h3" sx={{ color: palette.text.inverse, marginBottom: spacing(2) }}>
            {t('contributor_lohp_signup_title')}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              color: palette.text.inverse,
              marginTop: spacing(1.75),
              marginBottom: spacing(1.75),
            }}
          >
            {t('contributor_lohp_signup_subtitle')}
          </Typography>
          <Button
            variant="primary"
            href={NON_C2N_ROUTES.SIGN_UP}
            sx={{ marginBottom: spacing(3), minWidth: '100%' }}
            trackAction={NavTrackActions.signUpLink}
          >
            {t('contributor_lohp_signup_cta')}
          </Button>
          <Divider
            flexItem
            sx={{
              bgcolor: palette.background.iron,
            }}
          />
          <Box sx={{ display: 'flex', marginTop: spacing(1), gap: spacing(1) }}>
            <Typography variant="body1" sx={{ color: palette.text.inverse }}>
              {t('contributor_lohp_signup_existing_account')}
            </Typography>
            <Link
              href={NON_C2N_ROUTES.LOGIN}
              sx={{ color: palette.link.secondary }}
              trackAction={NavTrackActions.loginLink}
            >
              {t('contributor_header_sign_in')}
            </Link>
          </Box>
        </Box>
      </Box>
    </PageSection>
  );
};

export { HeroHeader, HeroFooter };
