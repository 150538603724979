import { SxProps } from '@mui/material/styles';
import SvgIcon from '@mui/material/SvgIcon';

export const EmailIcon = ({ sx }: { sx: SxProps }) => (
  <SvgIcon sx={sx}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 16C20 17.103 19.103 18 18 18H4V7.582L12 13L20 7.572V16ZM18.754 6L12 10.599L5.227 6H18.754ZM2 4V20H18C20.206 20 22 18.206 22 16V4H2Z"
    />
  </SvgIcon>
);
