import Box from '@mui/material/Box';
import { Theme, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'next-i18next';

import { ICON_SIZE } from '@/constants/icons';
import { DocumentIcon } from '@/icons/DocumentIcon';
import { EmailIcon } from '@/icons/EmailIcon';
import { PhotosIcon } from '@/icons/PhotosIcon';
import { UserIcon } from '@/icons/UserIcon';
import { NAMESPACE_LOHP } from '@/translations/namespaces';

const styles = {
  sellingPointsGrid: ({ breakpoints, spacing }: Theme) => ({
    display: 'grid',
    gridGap: spacing(2, 2),
    gridTemplateColumns: 'repeat(2, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    [breakpoints.down('sm')]: {
      gridTemplateColumns: 'repeat(1, 1fr)',
      gridTemplateRows: 'repeat(4, 1fr)',
    },
    [breakpoints.up('lg')]: {
      gridTemplateColumns: 'repeat(4, minmax(250px, 1fr))',
      gridTemplateRows: 'repeat(1, 1fr)',
    },
  }),
  sellingPointsCard: ({ spacing, palette }: Theme) => ({
    padding: spacing(2),
    textAlign: 'center',
    border: `1px ${palette.border.light} solid`,
  }),
};

export const SellingPoints = () => {
  const { t } = useTranslation(NAMESPACE_LOHP);

  const {
    spacing,
    tokens: { font },
    breakpoints,
  } = useTheme();

  return (
    <Box
      data-testid="selling-points-container"
      sx={{ marginTop: spacing(4), marginBottom: spacing(6), [breakpoints.up('lg')]: { marginBottom: spacing(12) } }}
    >
      <Typography
        data-testid="selling-points-title"
        component="h3"
        variant="h3"
        sx={{
          marginBottom: spacing(2),
          [breakpoints.down('sm')]: {
            lineHeight: font.lineHeight.regular,
            fontSize: font.size.h4,
          },
        }}
      >
        {t('contributor_lohp_selling_points_title')}
      </Typography>
      <Box sx={styles.sellingPointsGrid}>
        <Box sx={styles.sellingPointsCard}>
          <PhotosIcon sx={{ fontSize: ICON_SIZE.LARGE }} />
          <Typography variant="h4" sx={{ fontWeight: font.weight.normal }}>
            {t('contributor_lohp_selling_points_create_title')}
          </Typography>
          <Typography variant="body1">{t('contributor_lohp_selling_points_create_subtitle')}</Typography>
        </Box>
        <Box sx={styles.sellingPointsCard}>
          <DocumentIcon sx={{ fontSize: ICON_SIZE.LARGE }} />
          <Typography variant="h4" sx={{ fontWeight: font.weight.normal }}>
            {t('contributor_lohp_selling_points_submit_title')}
          </Typography>
          <Typography variant="body1">{t('contributor_lohp_selling_points_submit_subtitle')}</Typography>
        </Box>
        <Box sx={styles.sellingPointsCard}>
          <EmailIcon sx={{ fontSize: ICON_SIZE.LARGE }} />
          <Typography variant="h4" sx={{ fontWeight: font.weight.normal }}>
            {t('contributor_lohp_selling_points_get_paid_title')}
          </Typography>
          <Typography variant="body1">{t('contributor_lohp_selling_points_get_paid_subtitle')}</Typography>
        </Box>
        <Box sx={styles.sellingPointsCard}>
          <UserIcon sx={{ fontSize: ICON_SIZE.LARGE }} />
          <Typography variant="h4" sx={{ fontWeight: font.weight.normal }}>
            {t('contributor_lohp_selling_points_refer_title')}
          </Typography>
          <Typography variant="body1">{t('contributor_lohp_selling_points_refer_subtitle')}</Typography>
        </Box>
      </Box>
    </Box>
  );
};
