import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import dynamic from 'next/dynamic';

import { LOHPSiteHeader } from '@/components/SiteHeader/LoggedOut/LOHPSiteHeader';

type LOHPLayoutProps = {
  children: React.ReactNode;
};

const DynamicSiteFooter = dynamic(() => import('@/components/SiteFooter').then((mod) => mod.SiteFooter));

export const LOHPLayout = ({ children }: LOHPLayoutProps) => {
  const styles = {
    minWidth: '100%',
    position: 'relative',
  };

  return (
    <Box sx={{ position: 'relative' }}>
      <Box
        data-testid="LOHP-Header-layout-container"
        sx={{
          position: 'absolute',
          top: 0,
          height: ({ spacing }) => spacing(7),
          width: '100%',
          zIndex: 10,
        }}
      >
        <LOHPSiteHeader
          layoutStyles={{
            minWidth: '100%',
          }}
        />
      </Box>
      <Container data-testid="LOHP-content-layout-container" disableGutters sx={styles}>
        {children}
      </Container>
      <DynamicSiteFooter />
    </Box>
  );
};
